<template>
  <div class="page-notfound--inner">
    this page missing fool!
  </div>
</template>

<script>
export default {
  name: 'page-not-found',
  created () {
  }
}
</script>
